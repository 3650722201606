<template>
  <v-dialog
    v-model="status"
    :width="width"
    persistent
  >
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        {{ title }}
        <v-spacer />
        <v-btn
          text
          x-small
          fab
          @click="$emit('close')"
        >
          <v-icon color="error">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <slot />
      </v-card-text>

      <v-divider v-if="!hideActions" />
      <v-card-actions v-if="!hideActions">
        <v-btn
          v-if="isUpdate && !hideRemoveBtn"
          color="error"
          text
          small
          fab
          @click="$emit('remove')"
        >
          <v-icon>
            {{ icons.mdiDelete }}
          </v-icon>
        </v-btn>
        <v-spacer />
        <v-btn
          v-if="!hideCloseBtn"
          color="secondary"
          outlined
          @click="$emit('close')"
        >
          Fechar
        </v-btn>
        <v-btn
          v-if="!hideSaveBtn"
          color="primary"
          @click="$emit('action', isUpdate)"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiClose, mdiDelete } from '@mdi/js'

export default {
  props: {
    status: {
      default: true,
      type: Boolean,
    },
    width: {
      default: 900,
      type: Number,
    },
    title: {
      required: true,
      type: String,
    },
    isUpdate: {
      default: false,
      type: Boolean,
    },
    hideActions: {
      default: false,
      type: Boolean,
    },
    hideSaveBtn: {
      default: false,
      type: Boolean,
    },
    hideCloseBtn: {
      default: false,
      type: Boolean,
    },
    hideRemoveBtn: {
      default: false,
      type: Boolean,
    },
  },

  setup() {
    return {
      icons: {
        mdiClose,
        mdiDelete,
      },
    }
  },
}
</script>
